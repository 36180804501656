<template>
    <div class="card">
        <div class="p-grid" style="padding: 1em 1em 1em 1em;">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            <div class="p-col-12">
				<DataTable :value="products" :paginator="true" stripedRows class="p-datatable-customers p-datatable-sm"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
						:rowsPerPageOptions="[5, 10, 20, 50]" scrollable scrollHeight="400px">
					<template #header>
						<div class="table-header">
							<div>{{$t('Records')}}: {{ products.length }}</div>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Search')+'...'" />
                            </span>
							<div>
								<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('New')" @click="New" v-if="insert"/>
								<Button icon="pi pi-refresh" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Refresh')" @click="Start"/>
							</div>
						</div>
					</template>
					<Column field="id" :header="$t('ID')" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="ident_passport" :header="'ID / '+$t('Passport')" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.ident_passport}}
						</template>
					</Column>
					<Column field="name" :header="$t('Name')">
						<template #body="slotProps">
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="nationality_en" :header="$t('Nationality')">
						<template #body="slotProps">
							{{slotProps.data['nationality_'+i18n.locale()]}}
						</template>
					</Column>
					<Column :header="$t('Progress')" headerStyle="width: 170px">
						<template #body="slotProps">
							<ProgressBar :value="slotProps.data.porcent" style="height: 20px"></ProgressBar>
						</template>
					</Column>
					<Column :header="$t('Actions')" headerStyle="width: 160px">
						<template #body="slotProps">
							<div v-if="edit">
								<Button icon="pi pi-shield" style="background-color: red;" class="p-button-rounded  p-button-secondary p-mr-1" disabled v-if="slotProps.data.list>0 && !slotProps.data.nolist"  v-tooltip.bottom="$t('Check Person')"/>
								<Button icon="pi pi-shield" style="background-color: green;" class="p-button-rounded  p-button-secondary p-mr-1" @click="Check(slotProps.data)" v-else-if="slotProps.data.porcent==100"  v-tooltip.bottom="$t('Check Person')"/>
								<Button icon="pi pi-shield" style="background-color: yellow;" class="p-button-rounded  p-button-secondary p-mr-1" @click="Check(slotProps.data)" v-else v-tooltip.bottom="$t('Check Person')"/>
								<Button icon="pi pi-pencil" class="p-button-rounded  p-button-secondary p-mr-1" @click="Edit(slotProps.data)" v-tooltip.bottom="$t('Edit')"/>
								<Button icon="pi pi-trash"  class="p-button-rounded p-button-secondary p-mr-1" @click="confirmDeleteProduct(slotProps.data)" v-if="del"  v-tooltip.bottom="$t('Delete')"/>
							</div>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Edit')" :modal="true" class="p-fluid" :maximizable="true">
					<Person :nations="nations" :option="options" :alls="true"/>
					{{$t('The total of the annual premiums you pay are equal to or exceed the USD. 10,000.00')}}
					<div class="p-col-12 p-md-12 p-lg-4">
						<SelectButton v-model="$store.state.person.income" :options="options" :optionLabel="i18n.locale()"/>
					</div>
					<Income :option="options2" v-if="$store.state.person.income.id"/>
					<template #footer>
						<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="Save()"/>
						<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="productDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}"  :header="$t('Confirm')" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">{{$t('Are you sure you want to delete?')}}</span>
					</div>
					<template #footer>
						<Button :label="$t('Yes')" icon="pi pi-check" class="p-button-secondary" @click="Delete" />
						<Button :label="$t('No')" icon="pi pi-times" class="p-button-secondary" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="CheckDialog" :style="{width: size}" :header="$t('Check Person')" :modal="true" class="p-fluid" :maximizable="true">
					<Check :nations="nations" :option="options" :alls="true"/>
					<div class="p-grid">
						<div class="p-col-12 p-md-12 p-lg-12">
							<label>{{$t('Comment')}}</label>
							<Textarea v-model="$store.state.person.comments" rows="2"/>
						</div>
					</div>
					<template #footer>
						<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="SaveCheck()"/>
						<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="CheckDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	var Consult = new API('Registration','Persons');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Person: defineAsyncComponent(() => import('../components/Person.vue')),
			Income: defineAsyncComponent(() => import('../components/Income.vue')),
			Check: defineAsyncComponent(() => import('../components/Check_Person.vue')),
		},
		data() {
			return {
				i18n: null,
				newid: false,
				search: null,
				option: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				CheckDialog: false,
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				nations: [],
				options: [
					{id: 1, en: 'YES', es: 'SÍ'}, 
					{id: 0, en: 'NO', es: 'NO'}
				],
				options2: [
					{id: 1, en: 'from 250 thousand', es: 'from 250 thousand'}, 
					{id: 2, en: '250 to 1 million US$', es: '250 to 1 million US$'},
					{id: 3, en: '1 million to 10 million US$', es: '1 million to 10 million US$'},
					{id: 4, en: 'more - 10 million US$', es: 'more - 10 million US$'}
				],

			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
				this.product = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response.result.persons;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.nations = response.result.nations;
					this.products = response.result.persons;
					this.$store.commit('Loading');
				}); 
			},
			New() {
				this.newid = true;
				this.$store.state.person = {
					name: '',
					nationality: null,
					email: '',
					telephone: null,
					residence: '',
					profession: '',
					ident_passport: '',
					doc_pasaporte: null,
					pep: null,
					income: {id: 0, en: 'NO', es: 'NO'},
					form: null,
				};
				this.$store.state.income = {
					orig_activity: null,
					orig_funds: null,
					orig_prod_serv: null,
					orig_payment_method: null,
					income_activity: null,
					income_other: null,
					ref1_name: null,
					ref1_activity: null,
					ref1_relationship: null,
					ref1_phone: null,
					ref2_name: null,
					ref2_activity: null,
					ref2_relationship: null,
					ref2_phone: null,
					doc_constituent_document: null,
					doc_pasaporte: null,
					doc_certificate: null,
				};
				this.productDialog = true;
			},
			Edit(product) {
				this.newid = false;
				this.$store.state.person = {...product};
				this.$store.state.person.pep = this.options.filter(val => val.id == this.$store.state.person.pep)[0];
				this.$store.state.person.income = this.options.filter(val => val.id == this.$store.state.person.income)[0];
				this.$store.state.person.nationality = this.nations.filter(val => val.id == this.$store.state.person.nationality)[0];
				if(this.$store.state.person.income.id && this.$store.state.person.incomes.length){
					this.$store.state.income = this.$store.state.person.incomes[0];
					this.$store.state.income.income_activity = this.options2.filter(val => val.id == this.$store.state.income.income_activity)[0];
					this.$store.state.income.income_other = this.options2.filter(val => val.id == this.$store.state.income.income_other)[0];
				} else {
					this.$store.state.income = {
						orig_activity: null,
						orig_funds: null,
						orig_prod_serv: null,
						orig_payment_method: null,
						income_activity: null,
						income_other: null,
						ref1_name: null,
						ref1_activity: null,
						ref1_relationship: null,
						ref1_phone: null,
						ref2_name: null,
						ref2_activity: null,
						ref2_relationship: null,
						ref2_phone: null,
						doc_constituent_document: null,
						doc_pasaporte: null,
						doc_certificate: null,
					};
				}
				this.productDialog = true;
			},
			Save() {
				var person = this.$store.state.person;
				var income = this.$store.state.income;
				if (person.name == '' || person.name == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.nationality == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.email == '' || person.email == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.telephone == '' || person.telephone == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.residence == '' || person.residence == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.profession == '' || person.profession == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.ident_passport == '' || person.ident_passport == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.pep == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.doc_passport == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.income.id && income.income_activity == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else if (person.income.id && income.income_other == null) {
					this.$toast.add({severity:'error', summary: this.$t('Message'), detail: this.$t('Incomplete data'), life: 5000});
				} else {	
					person.incomes = income;
					let condition = 'UPD';
                    if (this.newid ) { condition = 'INS'; }
					Consult.Process(condition,{
						inf: person,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
						if (response.result) {
							this.productDialog = false;
							this.Start();
						} 
					});
				}
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			Delete() {
				Consult.Process('DEL',{
					inf: 	this.product.id
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.deleteProductDialog = false;
						this.Start();
					} 				
				});
			},
			Check(product) {
				this.newid = false;
				this.$store.state.person = {...product};
				this.$store.state.person.pep = this.options.filter(val => val.id == this.$store.state.person.pep)[0];
				this.$store.state.person.income = this.options.filter(val => val.id == this.$store.state.person.income)[0];
				this.$store.state.person.nationality = this.nations.filter(val => val.id == this.$store.state.person.nationality)[0];
				this.CheckDialog = true;
			},
			SaveCheck() {
				var person = this.$store.state.person;
				Consult.Process('Check',{
					inf: person,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.CheckDialog = false;
						this.Start();
					} 
				});
			},

		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>