<template>
    <div class="card">
        <div class="p-grid" style="padding: 1em 1em 1em 1em;">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            <div class="p-col-12">
				<DataTable :value="products" :paginator="true" stripedRows class="p-datatable-customers p-datatable-sm"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
						:rowsPerPageOptions="[5, 10, 20, 50]" scrollable scrollHeight="400px">
					<template #header>
						<div class="table-header">
							<div>{{$t('Records')}}: {{ products.length }}</div>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Search')+'...'" />
                            </span>
							<div>
								<Button icon="pi pi-refresh" class="p-button-rounded p-button-secondary p-mr-2 p-mb-2" v-tooltip.bottom="$t('Refresh')" @click="Start"/>
							</div>
						</div>
					</template>
					<Column field="id" :header="$t('ID')" :sortable="true" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="email" :header="$t('Email')" :sortable="true" headerStyle="width: 300px">
						<template #body="slotProps">
							{{slotProps.data.email}}
						</template>
					</Column>
					<Column field="name" :header="$t('Name')" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column :header="$t('Last entry')" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.login}}
						</template>
					</Column>
					<Column :header="$t('Actions')" headerStyle="width: 120px">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-secondary p-mr-2" @click="View(slotProps.data)" v-if="edit" v-tooltip.bottom="'View'"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}"  :header="$t('Details')" :modal="true" :maximizable="true">
					<br>
                    <div class="p-field">
						<DataTable :value="details" scrollable class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
							scrollHeight="300px" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers2" :filters="filters2">
							<template #header>
								<div class="table-header">
									<span class="p-input-icon-left">
										<i class="pi pi-search"/>
										<InputText v-model="filters2['global']" placeholder="Buscar..." />
									</span>
								</div>
							</template>
							<Column field="date" :header="$t('Date')" :sortable="true"/>
							<Column field="type" :header="$t('Type')" :sortable="true"/>
							<Column field="tables" :header="$t('Table')" :sortable="true"/>
							<Column field="code" :header="$t('Code')" :sortable="true"/>
							<Column field="ip" :header="$t('IP')" :sortable="true"/>
						</DataTable>	
					</div>

				</Dialog>

			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	var Consult = new API('Security','Logs');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null,
				newid: false,
				search: null,
				options: null,
				option: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				selectedCustomers2: null,
				filters: {},
				filters2: {},

				size: '60vw',
				details: [],
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
				this.product = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.products = response.result;
					this.$store.commit('Loading');
				}); 
			},
			View(data) {
				this.details = [];
				Consult.Process('View',{
					inf: data,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.details = response.result;
						this.productDialog = true;
					} 
				});
			},
			Search() {
				if (this.buscar || this.opcion.code==0){
					this.products = [];
					this.detalles = [];
					this.$store.commit('Loading');
					const Consulta = new API('Configuracion');
					Consulta.Procesar('Auditar',{
						caso: 	'Search',
						id: 	this.buscar,
                        opcion: 	this.opcion.code,
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if(this.opcion.code<3){
							this.products = response.result;
						} else {
							this.detalles = response.result;
							this.productDialog = true;
						}
						if(this.opcion.code==0){this.$store.state.conectados = response.result.length;}
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},

		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>