<template>
    <div class="card">
        <div class="p-grid dashboard" style="padding: 1em 1em 1em 1em;">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
			<div class="p-grid p-col-12 p-md-12 p-lg-12" v-if="view">
				<div class="p-col-12 p-lg-3">					
					<Card class="summary" style="height: 150px;">					
						<template #title>
							<span class="title">{{$t(product.business[i18n.locale()])}} </span>
							<span class="count visitors">{{product.business.cant}}</span>					
						</template>
						<template #content>
							<span class="detail">
								{{$t('Completed')}}: {{product.business.comp}}<br>
								{{$t('Blacklist')}}: {{product.business.list}}<br>
								{{$t('In Process')}}: {{product.business.cant-product.business.list-product.business.comp}}<br>
							</span>
						</template>
					</Card>
				</div>
				<div class="p-col-12 p-lg-3">					
					<Card class="summary" style="height: 150px;">					
						<template #title>
							<span class="title">{{$t(product.persons[i18n.locale()])}} </span>
							<span class="count visitors">{{product.persons.cant}}</span>					
						</template>
						<template #content>
							<span class="detail">
								{{$t('Completed')}}: {{product.persons.comp}}<br>
								{{$t('Blacklist')}}: {{product.persons.list}}<br>
								{{$t('In Process')}}: {{product.persons.cant-product.persons.list-product.persons.comp}}<br>
							</span>
						</template>
					</Card>
				</div>
				<div class="p-col-12 p-lg-3">					
					<Card class="summary" style="height: 150px;">					
						<template #title>
							<span class="title">{{$t(product.blacklist[i18n.locale()])}} </span>
							<span class="count visitors">{{product.blacklist.business+product.blacklist.persons}}</span>					
						</template>
						<template #content>
							<span class="detail">
								{{$t('Business')}}: {{product.blacklist.business}}<br>
								{{$t('Persons')}}: {{product.blacklist.persons}}<br>
							</span>
						</template>
					</Card>
				</div>
				<div class="p-col-12 p-lg-3">					
					<Card class="summary" style="height: 150px;">					
						<template #title>
							<span class="title">{{$t(product.users[i18n.locale()])}} </span>
							<span class="count visitors">{{product.users.cant}}</span>					
						</template>
						<template #content>
							<span class="detail">
								{{$t('Assets')}}: {{product.users.active}}<br>
								{{$t('Inactive')}}: {{product.users.noactive}}<br>
							</span>
						</template>
					</Card>
				</div>
				<div class="p-col-12 p-lg-6" >						
					<Card class="summary">
						<template #title>
							<span class="title">{{$t('Consultations')}}</span>
						</template>
						<template #content>
							<Chart type="bar" :data="graph1"/>
						</template>
					</Card>
				</div>
				<div class="p-col-12 p-lg-6" >						
					<Card class="summary">
						<template #title>
							<span class="title">{{$t('Reports')}}</span>
						</template>
						<template #content>
							<Chart type="line" :data="graph2"/>
						</template>
					</Card>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	var Consult = new API('Dashboard','KPI');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null,
				newid: false,
				search: null,
				options: null,
				option: null,
				insert: false,
				edit: false,
				del: false,
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				view: false,
				graph1: {
					labels: [],
					datasets: [
						{
							label: 'Business',
							data: [],
							fill: false,
							backgroundColor: 'green',
							borderColor: null
						},
						{
							label: 'Persons',
							data: [],
							fill: false,
							backgroundColor:  'red',
							borderColor: null
						},
					]
				},
				graph2: {
					labels: [],
					datasets: [
						{
							label: 'Business',
							data: [],
							fill: false,
							backgroundColor: 'green',
							borderColor: null
						},
						{
							label: 'Persons',
							data: [],
							fill: false,
							backgroundColor:  'red',
							borderColor: null
						},
					]
				}

			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
				this.view = false;
				this.product = {};
				Consult.Ini().then(response => {
					//this.$store.state.error = response;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
					this.product = response.result;
					this.graph1.labels = response.result.labels;
					this.graph2.labels = response.result.labels;
					this.graph1.datasets[0].data = response.result.graphs.business;
					this.graph1.datasets[1].data = response.result.graphs.persons;
					this.graph2.datasets[0].data = response.result.graphs.rep_business;
					this.graph2.datasets[1].data = response.result.graphs.rep_persons;
					this.view = true;
					this.$store.commit('Loading');
				}); 
			},
		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>