<template>
	<div class="card">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            
            <div class="p-fluid" style="padding: 1em 1em 1em 1em;">
                <div class="p-grid">
                    <div class="p-col-12 p-md-10"  style="padding: 1em 1em 1em 1em;">
                        <Accordion :activeIndex="0">
                            <AccordionTab :header="$t('English')">
                                <Editor v-model="product.en" editorStyle="height: 200px"/>
                            </AccordionTab>
                            <AccordionTab :header="$t('Spanish')">
                                <Editor v-model="product.es" editorStyle="height: 200px"/>
                            </AccordionTab>
                        </Accordion>                        
                    </div>
                    <div class="p-col-12 p-md-2">
                        <div class="p-grid" style="align-items: right;  justify-content: right;">
                            <div class="p-col-12 p-lg-12">
                                <Button :label="$t('Save')" icon="pi pi-save" class="p-button-secondary" @click="Save"/>
                            </div>
                            <div class="p-col-12 p-lg-12">
                                <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="Start"/>
                            </div>
                        </div>
                    </div>                    
                </div>
                
		</div>
	</div>
</template>

<script>
import API from "../service/API";
var Consult = new API('Parameters','Code');

import { useI18nPlugin } from '@unify/vuex-i18n';

export default {

    data() {
        return {
            product: {},
            size: '50%',
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '90%';}
        this.$store.commit('Validate',this.$route);
        this.i18n = useI18nPlugin();
        this.Start();
    },
    methods: {
        Start() {
			this.profile = {};
			Consult.Ini().then(response => {
                this.$store.commit('Loading');
				//this.$store.state.error = response;
                this.product = response.result;
			}); 
		},
        Save() {
            Consult.Process('UPD',{
                product: this.product
            }).then(response => {
				//this.$store.state.error = response;
				this.$store.commit('Loading');
                this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
				if (response.result) {
					this.Start();
				} 
			}); 
        },


	},

}
</script>
<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}

.imgRedonda {
    width:300px;
    border-radius:150px;
}
</style>