import jsPDF from 'jspdf';
import 'jspdf-autotable';

var pdf = new jsPDF();
var logo = "logo.jpg";

class PDFs {

    Plantilla(){
        pdf.addImage(logo, 'JPEG', 20, 10, 45, 20);
        pdf.setFontSize(10);
        pdf.setFont("italic","normal");
        pdf.text(100, 275, 'International Insurance Company N.V. – Registration Number 102716', 'center');
        pdf.text(100, 279, 'World Trade Center, Piscadera Bay Z/N, Willemstad – Curaçao', 'center');
        pdf.text(100, 283, 'Phone +59996761002 - +59996905811', 'center');
        pdf.text(100, 287, 'Website: www.internationalinsurancenv.com   e-mail: cvdestefano@internationalinsurancenv.com', 'center');
    }

    Tabla(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [114, 115, 118]}, 
            columnStyles: {text: {columnWidth: 'auto'}},             
            styles: {
                //font: 'courier',
                lineColor: [114, 115, 118],
                lineWidth: 0.1
            },
        });
    }

    Out(){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"), "Name.pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }

    moneda(number){
        return new Intl.NumberFormat("de-DE", {style: "currency", currency: "ANG"}).format(parseFloat(number).toFixed(2));
    }
    

    Report(data) {
        this.Plantilla();     
        pdf.setFontSize(11);
        pdf.setFont("italic", "bold");
        let titulo = "REPORTING FORM REGARDING THE IMPLEMENTATION OF SANCTIONS SCREENNING";
        pdf.text(titulo, 105, 45, {maxWidth: 170, align: "center"});

        pdf.text(data.name, 25, 90, {maxWidth: 170, align: "justify"});
        pdf.text(data.address, 25, 120, {maxWidth: 170, align: "justify"});
        pdf.text(data.email, 25, 150);
        pdf.text(data.telephone, 120, 150);

        pdf.setFont("italic","normal");
        var parrafo = 'This is a notification form to be sent to the regulators while taking restrictive measures in accordance with the sanctions lists, following international development and in observance of the freezing of resources for the implementation of sanctions imposed by International Organizations.';
        pdf.text(parrafo, 20, 60, {maxWidth: 170, align: "justify"});


        pdf.rect(20, 80, 170, 15); 
        pdf.text("Institution’s name / Client’s name / Applicant’s name", 20, 100);

        pdf.rect(20, 110, 170, 14); 
        pdf.text("Address", 20, 130);

        pdf.rect(20, 140, 170, 14); 
        pdf.text("Contact email", 20, 160);
        pdf.text("Phone number", 120, 160);

        pdf.rect(20, 170, 170, 25); 
        pdf.text("Description of the sanctions hit", 20, 200);

        pdf.rect(20, 210, 170, 14); 
        pdf.text("Amount value of the transaction", 20, 230);

        pdf.rect(20, 240, 170, 14); 
        pdf.text("History with the company (new/old client)", 20, 260);

        pdf.addPage();
        this.Plantilla();     
        if(data.list1 || data.list2){pdf.text("X", 26, 81);}
        if(data.list3){pdf.text("X", 126, 51);}
        if(data.list4){pdf.text("X", 126, 71);}
        if(data.list5){pdf.text("X", 126, 81);}

        pdf.text("Sanctions List screened:", 20, 40);
        pdf.text("(Relevant Match information)", 80, 42);

        pdf.rect(20, 45, 15, 10); 
        pdf.rect(35, 45, 50, 10); 
        pdf.text("FATF Black list", 37, 51);
        
        pdf.rect(20, 55, 15, 10); 
        pdf.rect(35, 55, 50, 10); 
        pdf.text("FATF Gray list", 37, 61);

        pdf.rect(20, 65, 15, 10); 
        pdf.rect(35, 65, 50, 10); 
        pdf.text("Basel AML Index", 37, 71);

        pdf.rect(20, 75, 15, 10); 
        pdf.rect(35, 75, 50, 10); 
        pdf.text("OFAC Program", 37, 81);

        pdf.rect(120, 45, 15, 10); 
        pdf.rect(135, 45, 50, 10); 
        pdf.text("EU Sanctions list", 137, 51);

        pdf.rect(120, 55, 15, 10); 
        pdf.rect(135, 55, 50, 10); 
        pdf.text("Corruption Index", 137, 61);

        pdf.rect(120, 65, 15, 10); 
        pdf.rect(135, 65, 50, 10); 
        pdf.text("UN list of Sanctions", 137, 71);

        pdf.rect(120, 75, 15, 10); 
        pdf.rect(135, 75, 50, 10); 
        pdf.text("National Terrorism List", 137, 81);
        
        pdf.text("Additional comments:", 20, 125);

        pdf.rect(20, 140, 170, 16); 
        pdf.text("Signature of the Person Submitting this Form", 20, 160);

        pdf.rect(20, 170, 170, 16); 
        pdf.text("Name of the Person Submitting this Form (print)", 20, 190);

        pdf.rect(20, 200, 170, 16); 
        pdf.text("Date of Signature", 20, 220);

        this.Out();     
    }

    

}


export default PDFs;