<template>
    <div class="card">
        <div class="p-grid" style="padding: 1em 1em 1em 1em;">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            <div class="p-col-12">
				<DataTable :value="products" :paginator="true" stripedRows class="p-datatable-customers p-datatable-sm"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
						:rowsPerPageOptions="[5, 10, 20, 50]" scrollable scrollHeight="400px">
					<template #header>
						<div class="table-header">
							<Dropdown v-model="search" :options="options" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'" @change="Search"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Search')+'...'" />
                            </span>
							<div>{{$t('Records')}}: {{ products.length }}</div>
						</div>
					</template>
					<Column field="id" :header="$t('ID')" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" :header="$t('Name')" headerStyle="width: 300px">
						<template #body="slotProps">
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="number" :header="$t('Number')" headerStyle="width: 300px">
						<template #body="slotProps">
							{{slotProps.data.number}}
						</template>
					</Column>
					<Column :header="$t('List')" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.list}}
						</template>
					</Column>
					<Column :header="$t('Upload')" headerStyle="width: 100px">
						<template #body="slotProps">
							<FileUpload :chooseLabel="$t('Upload')" uploadLabel="Cargar" mode="basic" :name="'report_'+token" :url="url+'upload.php'"  accept="application/pdf" :maxFileSize="1000000" @upload="Uploader(slotProps.data,'report_'+token)" :auto="true"/>
						</template>
					</Column>
					<Column :header="$t('Actions')" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-print" class="p-button-rounded  p-button-secondary p-mr-2" @click="PDF(slotProps.data)"  v-tooltip.bottom="$t('PDF')"/>
							<Button icon="pi pi-file-pdf" class="p-button-rounded  p-button-secondary p-mr-2" @click="View(slotProps.data.pdf_report)"  v-tooltip.bottom="$t('View')" v-if="slotProps.data.pdf_report"/>
							<Button icon="pi pi-trash" class="p-button-rounded  p-button-secondary p-mr-2" @click="Delpdf(slotProps.data)"  v-tooltip.bottom="$t('Delete')" v-if="slotProps.data.pdf_report"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	var Consult = new API('Dashboard','Reports');
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import PDF from "../service/PDF";

	export default {
		data() {
			return {
				i18n: null,
				newid: false,
				search: null,
				options: [
					{id: 1, en: 'Business', es: 'Business'},
					{id: 2, en: 'Persons', es: 'Persons'}
				],
				option: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				persons: [],
				business: [],
				lists: [],
				list: [],
				token: null,
				url: process.env.VUE_APP_API,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			const tokgen = new Token(256);
			this.token = tokgen.generate();

		},
		mounted() {
			
		},
		methods: {
			Search() {
				this.products = [];
				if(this.search){
					Consult.Process('Search',{
						inf: this.search.id,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.insert = response.insert;
						this.edit = response.edit;
						this.del = response.delete;
						this.lists = response.result.lists;
						this.products = response.result.products;
					});
				}
			},
			View(index){
				window.open(this.url+'upload/'+index, '_blank');
			},
			PDF(data){
				var product = data;
				product.type = this.search.id;
				const doc = new PDF();
				doc.Report(product);
				//this.$store.state.error = product;
			},
			Uploader(data,name) {
				var product = data;
				product.pdf_report = name;
				product.type = this.search.id;
				Consult.Process('Add',{
					inf: product,
				}).then(response => {
					//this.$store.state.error =response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if(response.result){	
						const tokgen = new Token(256);
						this.token = tokgen.generate();
						
						this.Search();
					}
				});
			},
			Delpdf(data) {
				var product = data;
				product.type = this.search.id;
				Consult.Process('Del',{
					inf: product,
				}).then(response => {
					//this.$store.state.error =response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if(response.result){				
						this.Search();
					} 
				});			
			},

		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>