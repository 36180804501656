<template>
    <div class="card">
        <div class="p-grid" style="padding: 1em 1em 1em 1em;">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            <div class="p-col-12 p-fluid">
                <Card>
                    <template #title>{{$t('Lookup')}}</template>
                    <template #content>
						<div class="p-grid">
                            <div class="p-col-12 p-md-12 p-lg-5 p-grid">
                                 <div class="p-col-12">
                                    <label for="name">{{$t('Name')}}</label>
                                    <InputText v-model="search.name" placeholder=""></InputText>						
                                </div>
                                <div class="p-col-12">
                                    <label for="name">{{$t('Type')}}</label>
                                    <Dropdown v-model="search.type" :options="types" optionLabel="name" :placeholder="$t('Select')+'...'"/>
                                </div>
                                <div class="p-col-12">
                                    <label for="name">{{$t('Program')}}</label>
                                    <Dropdown v-model="search.program" :options="programs" optionLabel="name" :placeholder="$t('Select')+'...'"/>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-lg-5 p-grid">
                                <div class="p-col-12">
                                    <label for="name">ID # / {{$t('Digital Currency Address')}}</label>
                                    <InputText v-model="search.id" placeholder=""></InputText>						
                                </div>
                                <div class="p-col-12">
                                    <label for="name">{{$t('Country')}}</label>
                                    <Dropdown v-model="search.country" :options="countrys" optionLabel="name" :placeholder="$t('Select')+'...'"/>
                                </div>
                                <div class="p-col-12">
                                    <label for="name">{{$t('List')}}</label>
                                    <Dropdown v-model="search.list" :options="lists" optionLabel="name" :placeholder="$t('Select')+'...'"/>
                                </div>
                            </div>
                            <div class="p-col-12 p-md-12 p-lg-2 p-grid">
                                <div class="p-col-12 p-grid" style=" display: flex; align-items: right; justify-content: right; height: 10px;">
                                    <div class="p-col-12 p-md-12 p-lg-12 p-grid">
                                        <Button :label="$t('Search')" icon="pi pi-search" class="p-button-secondary p-mr-2 p-mb-2" @click="Search()" />
                                    </div>
                                    <div class="p-col-12 p-md-12 p-lg-12 p-grid">
                                        <Button :label="$t('Reset')" icon="pi pi-replay" class="p-button-warning p-mr-2 p-mb-2" @click="Reset()" />
                                    </div>
                                    <div class="p-col-12 p-md-12 p-lg-12 p-grid" style=" display: flex; align-items: center; justify-content: center;" v-if="reference.length>1">
                                        <strong>Non-SDN, {{reference[0].dated}}</strong>
                                        <strong>SDN, {{reference[1].dated}}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Card>
            </div>

            <div class="p-col-12 p-fluid">
                <Card>
                    <template #title>{{$t('Results')}}: {{products.length}}</template>
                    <template #content>
                        <DataTable :value="products" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                                dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
                                selectionMode="single" @rowSelect="onRowSelect" 
                                scrollable scrollHeight="400px">
                            
                            <Column field="first_name" :header="$t('Name')">
                                <template #body="slotProps">
                                    <div v-if="slotProps.data.last_name">
                                        {{slotProps.data.last_name}}, {{slotProps.data.first_name}} 
                                    </div>
                                    <div v-else>
                                        {{slotProps.data.first_name}} 
                                    </div>   
                                </template>
                            </Column>
                            <Column field="address" :header="$t('Address')">
                                <template #body="slotProps">
                                    {{slotProps.data.address}}
                                </template>
                            </Column>
                            <Column field="type" :header="$t('Type')" headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{slotProps.data.type}}
                                </template>
                            </Column>
                            <Column field="program" :header="$t('Program')" headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{slotProps.data.program}}
                                </template>
                            </Column>
                            <Column field="list" :header="$t('List')" headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{slotProps.data.list}}
                                </template>
                            </Column>
                        </DataTable>
                    </template>
                </Card>
            </div>
            
            <Dialog v-model:visible="view" :style="{width: size}"  :header="$t('Details')" :modal="true">
                <br>
                <div class="p-grid" style="padding: 1em 1em 1em 1em;" v-if="product.type=='Individual'">
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Type')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.type}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('List')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.list}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Last Name')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.last_name}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Program')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.program}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('First Name')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.first_name}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Nationality')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.nationality}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Title')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.title}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Citizenship')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.citizenship}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Date of birth')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.date_of_birth}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Remarks')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.remarks}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Place of birth')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.place_of_birth}}
                    </div>
                </div>
                <div class="p-grid" style="padding: 1em 1em 1em 1em;" v-else>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Type')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.type}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('List')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.list}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Entity Name')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.last_name}}, {{product.first_name}} 
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Program')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-4">
                        {{product.program}}
                    </div>
                    <div class="p-col-4 p-md-4 p-lg-2">
                        {{$t('Remarks')}}:
                    </div>
                    <div class="p-col-8 p-md-8 p-lg-10">
                        {{product.remarks}}
                    </div>
                </div>
                <div class="p-grid" style="padding: 1em 1em 1em 1em;">
                    <div class="p-col-12" v-if="product.identification.length>0">
                        <strong>{{$t('Identifications')}}</strong>
                        <DataTable :value="product.identification" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                                dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" scrollable>
                            <Column field="type" :header="$t('Type')"></Column>
                            <Column field="number" :header="$t('ID')+'#'"></Column>
                            <Column field="country" :header="$t('Country')"></Column>
                        </DataTable>                       
                    </div>
                    <div class="p-col-12" v-if="product.aka.length>0">
                        <strong>{{$t('Aliases')}}</strong>
                        <DataTable :value="product.aka" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                                dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" scrollable>
                            <Column field="type" :header="$t('Type')"></Column>
                            <Column field="category" :header="$t('Category')"></Column>
                            <Column field="firstname" :header="$t('Name')">
                                <template #body="slotProps">
                                    {{slotProps.data.firstname}} {{slotProps.data.lastname}}
                                </template>
                            </Column>
                        </DataTable> 
                        <br>                           
                    </div>
                    <div class="p-col-12" v-if="product.address.length>0">
                        <strong>{{$t('Addresses')}}</strong>
                        <DataTable :value="product.address" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                                dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" scrollable>
                            <Column field="address1" :header="$t('Address')">
                                <template #body="slotProps">
                                    {{slotProps.data.address1}} {{slotProps.data.address2}} {{slotProps.data.address3}}
                                </template>
                            </Column>
                            <Column field="city" :header="$t('City')"></Column>
                            <Column field="postalcode" :header="$t('Postal Code')"></Column>
                            <Column field="country" :header="$t('Country')"></Column>
                        </DataTable>                            
                    </div>
                </div>
                <template #footer>
                    <Button :label="$t('Close')" icon="pi pi-times" class="p-button-secondary" @click="view = false"/>
                </template>
            </Dialog>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	var Consult = new API('Consultation','OFAC');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null,
				insert: false,
				edit: false,
				del: false,
				size: '60vw',
                product: {
                    address: [],
                    aka: [],
                    identification: []
                },
                products: [],
                types: [],
                state: null,
                countrys: [],
                programs: [],
                lists: [],
                reference: [],
                view: false,
                search: {
                    name: null,
                    type: {name: 'All'},
                    address: null,
                    city: null,
                    id: null,
                    country: {name: 'All'},
                    program: {name: 'All'},
                    list: {name: 'All'},
                },
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Start();
		},
		mounted() {
			
		},
		methods: {
			Start() {
                this.view = false;
				this.product = [];
				Consult.Ini().then(response => {
					//this.$store.state.error = response.result.countrys;
					this.insert = response.insert;
					this.edit = response.edit;
					this.del = response.delete;
                    this.reference = response.result.reference;
					this.types = [{name: 'All'}].concat(response.result.types);
					this.programs = [{name: 'All'}].concat(response.result.programs);
					this.lists = [{name: 'All'}].concat(response.result.lists);
                    this.countrys = [{name: 'All'}].concat(response.result.countrys);
					this.$store.commit('Loading');
				}); 
			},
			onRowSelect(event) {
				this.Select(event.data);
			},
            Reset(){
                this.search = {
                    name: null,
                    type: {name: 'All'},
                    address: null,
                    city: null,
                    id: null,
                    country: {name: 'All'},
                    program: {name: 'All'},
                    list: {name: 'All'},
                };
                this.products = [];
            },
			Search() {
                this.view = false;
				Consult.Process('Search',{
					inf: this.search,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					this.products = response.result;
				});
			},
			Select(data) {
                this.view = false;
                this.product = {...data};
				Consult.Process('Select',{
					inf: data,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
                    this.product.address = response.result.address;
                    this.product.aka = response.result.aka;
                    this.product.identification = response.result.identification;
                    this.view = true;
				});
			},
        }
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>