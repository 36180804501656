<template>
    <div class="card">
        <div class="p-grid" style="padding: 1em 1em 1em 1em;">
            <div class="ttitle">{{$store.state.title[i18n.locale()]}}</div>
            <div class="p-col-12">
				<DataTable :value="products" :paginator="true" stripedRows class="p-datatable-customers p-datatable-sm"
                        :rows="5" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters"
						:rowsPerPageOptions="[5, 10, 20, 50]" scrollable scrollHeight="400px">
					<template #header>
						<div class="table-header">
							<Dropdown v-model="search" :options="options" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'" @change="Search"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" :placeholder="$t('Search')+'...'" />
                            </span>
							<div>{{$t('Records')}}: {{ products.length }}</div>
						</div>
					</template>
					<Column field="id" :header="$t('ID')" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="name" :header="$t('Name')" headerStyle="width: 300px">
						<template #body="slotProps">
							{{slotProps.data.name}}
						</template>
					</Column>
					<Column field="number" :header="$t('Number')" headerStyle="width: 300px">
						<template #body="slotProps">
							{{slotProps.data.number}}
						</template>
					</Column>
					<Column :header="$t('List')" headerStyle="width: 60px">
						<template #body="slotProps">
							{{slotProps.data.list}}
						</template>
					</Column>
					<Column :header="$t('Status')" headerStyle="width: 60px">
						<template #body="slotProps">
							<span class="p-tag p-tag-info"  v-if="!slotProps.data.nolist">{{$t('Asset')}}</span>
							<span class="p-tag p-tag-info"  v-else>{{$t('Clueless')}}</span>
						</template>
					</Column>
					<Column :header="$t('Actions')" headerStyle="width: 60px">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded  p-button-secondary p-mr-2" @click="Edit(slotProps.data)"  v-tooltip.bottom="$t('Edit')"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="$t('Status')" :modal="true" class="p-fluid" :maximizable="true">
					<div>
                        <DataTable :value="list" :paginator="false" stripedRows class="p-datatable-customers p-datatable-sm"
                                dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" scrollable>
                            <Column field="name" :header="$t('Name')"></Column>
                            <Column field="ident" :header="$t('Number')+'#'"></Column>
                            <Column field="country" :header="$t('Country')"></Column>
							<Column :header="$t('List')" headerStyle="width: 100px">
								<template #body="slotProps">
									{{slotProps.data[i18n.locale()]}}
								</template>
							</Column>
                        </DataTable>                       
					</div>
					<br>
					<div class="p-grid">
						<div class="p-col-12 p-md-12 p-lg-12">
							<label>{{$t('Comment')}}</label>
							<Textarea v-model="product.comments" rows="2"/>
						</div>
						<div class="p-col-12 p-md-12 p-lg-12">
							<label class="p-mr-2">{{$t('No list')}} </label>
							<Checkbox v-model="product.nolist" :binary="true"/>
						</div>
					</div>
					<template #footer>
						<Button :label="$t('Save')" icon="pi pi-check" class="p-button-secondary" @click="Save()" />
						<Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-secondary" @click="productDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	var Consult = new API('Registration','List');
	import { useI18nPlugin } from '@unify/vuex-i18n';

	export default {
		data() {
			return {
				i18n: null,
				newid: false,
				search: null,
				options: [
					{id: 1, en: 'Business', es: 'Business'},
					{id: 2, en: 'Persons', es: 'Persons'}
				],
				option: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedCustomers: null,
				filters: {},
				size: '60vw',
				persons: [],
				business: [],
				lists: [],
				list: [],
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.$store.commit('Validate',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			Search() {
				this.products = [];
				if(this.search){
					Consult.Process('Search',{
						inf: this.search.id,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.insert = response.insert;
						this.edit = response.edit;
						this.del = response.delete;
						this.lists = response.result.lists;
						this.products = response.result.products;
					});
				}
			},
			Edit(data) {
				this.product = {...data};
				if(this.search.id==1){
					this.list = this.lists.filter(val => val.business == data.id);
				} else {
					this.list = this.lists.filter(val => val.persons == data.id);
				}
				this.productDialog = true;
			},
			Save() {
				var product = this.product;
				product.type = this.search.id;
				Consult.Process('UPD',{
					inf: product,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
						this.productDialog = false;
						this.Search();
					} 
				});
			},

		}
	}
</script>

<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
        font-family: 'Rajdhani', sans-serif;
        font-size: calc(0.7rem + 1vw);
        font-weight: 600;
        color: #2b3b80;
    }

	::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

.p-progressbar-value.ui-widget-header {
	background: #607d8b;
}

@media (max-width: 640px) {
	.p-progressbar {
		margin-top: .5rem;
	}
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
					}
				}
			}
		}
	}
}
</style>